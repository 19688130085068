import styled from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { Text } from '../Text/Text.js';
import { overflowWrap } from '../utils/styles/overflow-wrap.js';
import { Required } from '../Required/Required.js';

const StyledLabel = styled(Text).withConfig({
  componentId: "sg1690__sc-h1wuoo-0"
})(["display:flex;margin-bottom:", ";"], tokens.space.xs);
const StyledLabelHeadings = styled.div.withConfig({
  componentId: "sg1690__sc-h1wuoo-1"
})(["", " flex:1 1 100%;max-width:100%;"], overflowWrap);
const StyledLabelHeading = styled(Text).withConfig({
  componentId: "sg1690__sc-h1wuoo-2"
})(["display:inline;margin-right:", ";"], tokens.space.md);
const StyledRequired = styled(Required).withConfig({
  componentId: "sg1690__sc-h1wuoo-3"
})(["&::after{margin-left:", ";}"], tokens.space.xxs);
const StyledHelpButtonContainer = styled.span.withConfig({
  componentId: "sg1690__sc-h1wuoo-4"
})(["display:inline-block;transform:translateY(-0.15rem);"]);

export { StyledHelpButtonContainer, StyledLabel, StyledLabelHeading, StyledLabelHeadings, StyledRequired };
